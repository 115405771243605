
var checkboxSelector = '.js-dynamic-search input[type="checkbox"]:not(".js-applied-filters-exclude")';

function init() {
    if ($('.js-dynamic-search-replace-applied-filters').length) {
        attachAppliedFilterClick();
    }
    $('#appliedFilters').hide();
    setFilterDisplay();
}

function attachAppliedFilterClick() {
    window.site.body.on('click', '.js-applied-filter-remove', function() {
        var nameToUnCheck = decodeURIComponent($(this).attr('data-applied-filter-name')).replace('*', '^');
        var targetInput = $('input[name="' + nameToUnCheck + '"]');
        if (targetInput.length) {
            targetInput.prop('checked', false);
            targetInput.trigger('change');
        }
        $(this).remove();
    });
}

function attachCheckboxFilterChange() {
    $(checkboxSelector).on('change', function() {
        setFilterDisplay();
    });
}

function removeAll() {
    $('#appliedFilters button').not(':first').remove();
}

function setFilterDisplay() {
    removeAll();
    $('.m-search-page__form__clear-search').on('click', function() {
        $('#appliedFilters button').not(':first').remove();
        $('#appliedFilters').hide();
    });


    var selectedFilters = $('[name^="facilities"]:checked, [name^="holidayType"]:checked, [name^="collection"]:checked').toArray();
    selectedFilters.forEach(function(filter) {
        var ele = $($('.template')[0]).clone().appendTo("#appliedFilters").show();
        ele.find('button')[0].prepend(filter.name.split('^')[1]);
        ele.find('button').first().attr('data-applied-filter-name', filter.name);
    });

    $('#appliedFilters').show();
    if (selectedFilters.length < 1) {
        $('#appliedFilters').hide();
    }
}


export default init;
export { attachCheckboxFilterChange }
